<template>
  <div>
    <commonHeader :show-back="false" :show-area="true" :show-search="true" :showLoginText="true" :show-order="true" />
    <homeBanner :list="bannerList" v-if="bannerList.length" />
    <div class="subject-tabs" v-if="typeList.length">
      <van-tabs v-model="typeListActiveIndex" class="reset-style" sticky offset-top="0" @click="clickTabs">
        <van-tab :title="item.name" v-for="(item, index) in typeList" :key="index">
          <courseList :list="item.list" :finished="item.finished" :loading="item.loading" @loads="apiGetHomeList" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import {getBannerList, getHomeList} from '@/api/home'

import homeBanner from '@/components/home/home-banner'
import courseList from '@/components/course-list'
import commonMixins from "@/mixins/common";

export default {
  components: {
    homeBanner,
    courseList,
  },
  mixins: [commonMixins],
  data() {
    return {
      bannerList: [],
      typeList: [],
      typeListActiveIndex: 0,
      courseList: [],
      province: '',
      province_list: {},
    }
  },

  computed: {
    typeListActiveObject() {
      let typeList = this.typeList,
          typeListActiveIndex = this.typeListActiveIndex
      return typeList[typeListActiveIndex]
    },
  },
  mounted() {
    this.getCurrentProvince().then(() => {
      this.getTypeList()
      this.apiGetBannerList()
    })
   this.$util.setShare()
  },

  methods: {
    // 获取当前省份
    getCurrentProvince() {
      let province = this.$route.query.province
      return new Promise((resolve) => {
        if (province) {
          this.province = province
          resolve()
          return
        }

        this.$store.dispatch('getProvinceKey').then((province) => {
          this.province = province
          resolve()
        })
      })
    },
    // 获取首页广告列表
    apiGetBannerList() {
      let province = this.province
      getBannerList({province}).then((res) => {
        this.bannerList = res?.data || []
      })
    },

    //获取首页课程列表
    apiGetHomeList() {
      let {page, finished, id} = this.typeListActiveObject
      let province = this.province

      if (finished) {
        return
      }
      page = page + 1
      this.typeListActiveObject.loading = true
      getHomeList({province, type: id, page, length: 10}).then((res) => {
        let courseList = res.data
        this.typeListActiveObject.loading = false
        if (courseList.length < 10) {
          finished = true
        }
        this.typeListActiveObject.page = page
        this.typeListActiveObject.finished = finished
        this.typeListActiveObject.list = this.typeListActiveObject.list.concat(
            courseList
        )
        this.$set(
            this.typeList,
            this.typeListActiveIndex,
            this.typeListActiveObject
        )
      })
    },

    // 获取类型列表
    getTypeList() {
      this.$store.dispatch('getProvinceList').then(provinceList => {
        this.province_list = provinceList
        let type_list = provinceList[this.province].type_list
        this.typeList = this.formatTypeList(type_list)
        this.apiGetHomeList()
      })
    },
    // 格式化类型列表
    formatTypeList(list) {
      list.map((item) => {
        item.page = 0
        item.finished = false
        item.list = []
        item.loading = false
      })
      return list
    },

    clickTabs() {
      // this.apiGetHomeList()
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .subject-tabs {
    .van-tab {
      padding: 0 0.265rem;
    }

    .van-tabs__nav--complete,
    .van-tab:nth-child(1) {
      //padding-left: 0;
    }

    .van-tabs__wrap {
      height: 1.55rem;
      //padding-left: .53rem;
      background-color: #fff;
    }

    .van-tabs__line {
      bottom: 0.27rem;
    }
  }
}
</style>

import request from '@/utils/request'


export function getBannerList (data) {
    return request({
        url: 'banner/getList',
        method: 'post',
        data: data,
    })
}
export function getHomeList (data) {
    return request({
        url: 'home/getList',
        method: 'post',
        data: data,
    })
}


<template>
  <div class="home-banner">
    <van-swipe class="home-swipe" :autoplay="5000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in list" :key="index">
        <div class="box" @click="onClick(item)">
          <img :src="item.img" class="image">
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: "home-banner",
  props: ['list'],
  methods: {
    onClick (o) {
      let cmdData = o.cmd_data

      if (cmdData.func === 'url') {
        this.$util.locationHref(cmdData.param.url)

      }

      if (cmdData.func === 'user_course') {
        this.$router.push({ name: 'goodsDetail', query: { id: cmdData.param.id } })
      }

    }
  },
}
</script>

<style scoped lang="scss">
.home-swipe {
  height: 3.89rem;
}

.home-banner {
  padding-top: .27rem;
  background-color: #fff;

  .box {
    margin: 0 $containerPadding;
    height: 3.89rem;
    border-radius: .2rem;
    overflow: hidden;

    .image {
      height: 100%;
      width: 100%;
    }
  }

  ::v-deep {
    .van-swipe__indicators {
      bottom: .2rem;
    }

    .van-swipe__indicator {
      width: .11rem;
      height: .11rem;

    }

    .van-swipe__indicator--active {
      width: .32rem;
      border-radius: .5rem;
    }
  }
}
</style>